import { startTransition, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { KeyLoader } from 'swr';
import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite';

import { getFilterQuery, mergeUrlSearchParams } from '@hultafors/shared/api';
import {
  ParsedProductsApiResponse,
  ProductFilter,
  ProductFilterValue,
  QueryProductFilter,
} from '@hultafors/shared/types';

import {
  CategoryPageContentProps,
  PRODUCTS_PAGE_SIZE,
} from '@hultafors/snickers/api';
import { colors } from '@hultafors/snickers/helpers';
import { useGlobal, useProductBreadcrumbs } from '@hultafors/snickers/hooks';
import { SnickersProduct } from '@hultafors/snickers/types';

import { Breadcrumbs } from '../breadcrumbs/breadcrumbs';
import { Button } from '../Button/Button';
import { VisibleSeoBlock } from '../ContentArea/_contentAreaBlocks';
import { ContentArea } from '../ContentArea/ContentArea';
import { Filters } from '../Filters/Filters';
import { FilterSort } from '../FilterSort/FilterSort';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { H4 } from '../H4/H4';
import { HeroBlock } from '../hero-block/hero-block';
import { MobileFilterWrapper } from '../MobileFilterWrapper/MobileFilterWrapper';
import { Pager } from '../Pager/Pager';
import { Products } from '../Products/Products';
import { Section } from '../Section/Section';
import { SlideIn } from '../SlideIn/SlideIn';
import { TrashIcon } from '../TrashIcon/TrashIcon';

export const CategoryPageContent: React.FC<CategoryPageContentProps> = ({
  content,
  categoryGeneric,
  productCatalogNodeId,
  initialFilters = [],
  productListApiBaseUrl,
  fallbackData,
  parentSlug,
  categorySlug,
}) => {
  const { locale, ...router } = useRouter();
  const { global } = useGlobal();
  const breadcrumbs = useProductBreadcrumbs(router.asPath);
  // Hack to not fetch new data on initial filter set
  const [filtersInit, setFiltersInit] = useState(false);
  const [filters, setFilters] = useState<ProductFilter[]>(initialFilters);
  const selectedFilters = useMemo(
    () =>
      filters?.filter(
        ({ values }) => !!values.filter(({ active }) => active).length,
      ),
    [filters],
  );
  const selectedFilterValues = useMemo(
    () =>
      filters
        .filter(({ values }) => !!values.filter(({ active }) => active).length)
        .flatMap((filter) => filter.values.filter(({ active }) => active)),
    [filters],
  );

  // Creates api path that also acts as cache key for swr
  const getKey: KeyLoader = (
    index: number,
    previousPageData: ParsedProductsApiResponse<SnickersProduct> | null,
  ) => {
    if (
      previousPageData &&
      previousPageData.paging.pageCount === previousPageData.paging.pageNumber
    ) {
      return null;
    }
    const filterParams = getFilterQuery(filters);
    const params = mergeUrlSearchParams(
      new URLSearchParams({
        includeFilter: 'true',
        pageNumber: `${index + 1}`,
        pageSize: `${PRODUCTS_PAGE_SIZE}`,
        productCatalogNodeId,
        ...(locale && locale !== 'com' ? { locale } : {}),
      }),
      filterParams,
    );
    return `${productListApiBaseUrl}?${params.toString()}`;
  };

  // Config swr to use server side data
  const swrConfig: SWRInfiniteConfiguration<
    ParsedProductsApiResponse<SnickersProduct>
  > = {
    fallbackData,
    initialSize: fallbackData?.length || 0,
  };

  // Initialize swr for product data fetching
  const { data, isLoading, size, setSize } = useSWRInfinite<
    ParsedProductsApiResponse<SnickersProduct>
  >(getKey, swrConfig);

  const products = useMemo(() => {
    return data?.flatMap(({ items }) => items) || [];
  }, [data]);

  const pagerData = useMemo(() => {
    const length = data?.length || 0;
    return data?.[length - 1]?.paging;
  }, [data]);

  // Update data and browser location when filters change
  useEffect(() => {
    if (filtersInit) {
      // Set location
      const params = getFilterQuery(filters);
      const query = params.toString();
      const path = router.asPath.split('?')[0];
      router.push(`${path}${query && `?${query}`}`, undefined, {
        shallow: true,
      });

      // Set page to reset and fetch data
      startTransition(() => {
        setSize(1);
      });
    } else {
      startTransition(() => {
        setFiltersInit(true);
      });
    }
  }, [filters]);

  /**
   * Handles changed filters further down the tree
   * @param param0 Filter to toggle
   */
  const filterChange = ({ AttrId, ValueId }: QueryProductFilter) => {
    startTransition(() => {
      setFilters(
        filters.map((filter) => ({
          ...filter,
          values: filter.values.map((value) => ({
            ...value,
            active:
              `${filter.id}` === `${AttrId}` && `${value.id}` === `${ValueId}`
                ? !value.active
                : value.active,
          })),
        })),
      );
    });
  };

  const clearFilters = () => {
    startTransition(() => {
      setFilters(
        filters.map((filter) => ({
          ...filter,
          values: filter.values.map((value) => ({ ...value, active: false })),
        })),
      );
    });
  };

  const loadMore = () => {
    const newSize = size + 1;
    startTransition(() => {
      setSize(newSize);
    });
    const params = new URLSearchParams(router.query as Record<string, string>);
    params.delete('category');
    if (params.has('subCategory')) {
      params.delete('subCategory');
    }
    if (!newSize || newSize === 1) {
      params.delete('page');
    } else {
      params.set('page', `${newSize}`);
    }
    const query = params.toString();
    const path = router.asPath.split('?')[0];
    router.push(`${path}${query && `?${query}`}`, undefined, {
      shallow: true,
    });
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilter = () => {
    startTransition(() => {
      setIsFilterOpen(!isFilterOpen);
    });
  };

  const selectedFilterValueMapper = ({
    id,
    description,
  }: ProductFilterValue): React.ReactNode => (
    <span key={`SelectedFilterValue-${id}`}>{`${description}`}</span>
  );
  if (!content) {
    return null;
  }

  return (
    <>
      <Breadcrumbs {...breadcrumbs} slim />
      <HeroBlock
        id="category-page-hero"
        title={content.heroTitle || ''}
        preTitle={content.heroSubTitle || ''}
        description={content.heroDescription || ''}
        image={content.heroImage || undefined}
        imageLoad={content.imageLoad || undefined}
        imageThin={content.heroImage || undefined}
        mobileImage={content.heroImageMobile || undefined}
        tabletImage={content.heroImageTablet || undefined}
        heroContentBoxSmall
        boxColor={{ hex: colors.gray4 }}
        heroIcon
        wide
        thin
        priority
      />
      <Section>
        <Grid
          columns={12}
          columnGap={[
            { columnGap: 15 },
            { breakpoint: 'desktop', columnGap: 30 },
          ]}
        >
          <GridChild
            columnSpan={[
              { columns: 12 },
              { breakpoint: 'desktop', columns: 3 },
            ]}
          >
            <FilterSort toggleFilter={toggleFilter}>
              <>
                {selectedFilterValues.length > 0 && (
                  <div className="SelectedFilters">
                    <H4>{categoryGeneric.appliedFilters}</H4>
                    {selectedFilterValues.map(selectedFilterValueMapper)}
                    <button onClick={clearFilters} className="ClearFilters">
                      {categoryGeneric.filterClear}
                      <TrashIcon style={{ marginInlineStart: '8px' }} />
                    </button>
                  </div>
                )}
                <Filters filters={filters} filterChange={filterChange} />
              </>
            </FilterSort>
          </GridChild>
          <GridChild
            columnSpan={[
              { columns: 12 },
              { breakpoint: 'desktop', columns: 9 },
            ]}
          >
            <Grid
              columnGap={[
                { columnGap: 15 },
                { breakpoint: 'desktop', columnGap: 30 },
              ]}
              columns={[
                { columns: 1 },
                { breakpoint: 'mediumMobile', columns: 2 },
                { breakpoint: 'desktop', columns: 3 },
              ]}
            >
              <Products
                products={products}
                badgeText={global?.new || ''}
                rrpLabel={global.rrpLabel || ''}
                rrpIncludingVatLabel={global.rrpIncludingVatLabel || ''}
                categorySlug={parentSlug || categorySlug}
              />
            </Grid>
            <Pager
              buttonText={categoryGeneric.loadMoreButton || ''}
              ofText={categoryGeneric.pagerOf || ''}
              productsText={categoryGeneric.pagerProductsLabel || ''}
              onClick={loadMore}
              pagerData={pagerData}
              loading={isLoading}
            />
          </GridChild>
        </Grid>
      </Section>
      {content.seoSectionTitle && content.seoSectionDescription && (
        <VisibleSeoBlock
          title={content.seoSectionTitle}
          description={content.seoSectionDescription}
        />
      )}

      {content?.contentArea && <ContentArea content={content.contentArea} />}
      <SlideIn
        toggle={toggleFilter}
        isOpen={isFilterOpen}
        headerText={categoryGeneric.filterHeader || ''}
        headerIcon="/assets/gfx/filter.svg"
        clearText={categoryGeneric.filterClear || ''}
        clearAll={clearFilters}
        id="productListFilters"
      >
        <MobileFilterWrapper>
          {selectedFilters.length > 0 && (
            <div className="SelectedFilters">
              <H4>{categoryGeneric.appliedFilters}</H4>
              {selectedFilterValues.map(selectedFilterValueMapper)}
              <button onClick={clearFilters} className="ClearFilters">
                {categoryGeneric.filterClear}
                <TrashIcon />
              </button>
              <Button onClick={toggleFilter} small className="ApplyFilters">
                {categoryGeneric.applyFilters}
              </Button>
            </div>
          )}
          <Filters filters={filters} filterChange={filterChange} menu />
        </MobileFilterWrapper>
      </SlideIn>
    </>
  );
};
